import styled, { css } from 'styled-components';

const CustomButton = styled.button`
  width: 100px;
  color: #ffffff;
  background-color: #E97573;
  padding: 7px 15px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  float: ${({ left }) => left ? 'left' : 'right'};
  line-height: 1.428571429;
  text-transform: capitalize;
  font-family: "Rubik-Medium";
  outline: none;
  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
  `}
`;

const CustomOrderedList = styled.ol`
  margin: 0px
`;

export { CustomButton, CustomOrderedList };