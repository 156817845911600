import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from './styles';

import QuestionComponent from '../QuestionComponentV2';

import { saveMedicalData, getMedicalData } from '../../redux/actions';
import { withTranslation } from 'react-i18next';

class MedicalForm extends Component {

  componentDidMount() {
    this.props.fetchMedicalData();
  }

  showLifestyleForm = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/wellness-assessment/lifestyle');
  };

  render() {
    const { addMedicalData, medicalData, hraQuestionsAnswersData } = this.props;
    return (
      <form>
        <CustomOrderedList>
          {
            hraQuestionsAnswersData['medical'].map((section,index) => {
              let addedAnswers = '';
              medicalData && medicalData.length > 0 && medicalData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  section={section}
                  key={section.question}
                  addedAnswers={addedAnswers}
                  saveData={addMedicalData}
                  color={'#7164C4'}
                  questionNo={index+1}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton
          onClick={this.showLifestyleForm}
          disabled={_.isUndefined(medicalData) || medicalData.length<hraQuestionsAnswersData['medical'].length}
          // disabled={_.isUndefined(medicalData) || medicalData.filter((data) => hraQuestionsAnswersData['medical'].includes(data.question)).length < hraQuestionsAnswersData['medical'].length}
        >
          {this.props.t("next")}
        </CustomButton>
      </form>
    )
  }
}

MedicalForm.propTypes = {
  addMedicalData: PropTypes.func.isRequired,
  fetchMedicalData: PropTypes.func.isRequired,
  medicalData: PropTypes.array,
  history: PropTypes.object.isRequired,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  medicalData: state.wellBeingAssessment.medicalData
});

const mapDispatchToProps = (dispatch) => ({
  addMedicalData: (question, answer, isMultipleAnswers, count) => dispatch(saveMedicalData(question, answer, isMultipleAnswers, count)),
  fetchMedicalData: () => dispatch(getMedicalData())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(MedicalForm));