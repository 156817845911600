import styled from  'styled-components';

const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 57px;
  height: 30px;
  margin-bottom: 0;

  @media (max-width: 500px) {
    left: calc(100% - 60px);
  }
  
  > input {
    display: none;
  }
  
  > input:checked + .slider {
    background-color: #81d487;
  }

  > input:focus + .slider {
    box-shadow: 0 0 1px #81d487;
  }
  
  > input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #69c2ff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  
  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const Container = styled.div`
 width: 100%;
 background: white;
 display: flex;
 padding: 25px;
 display: flex;
 .units{
    width: 50%;
    display: flex;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
    align-items: center;
 }
 .check-box{
    display: flex;
    width: 50%;
 }
`;

const CheckBoxContainer = styled.div`
 width: 100%;
 display: flex;
 judtify-content: center;
 .active-unit{
    display: flex;
    font-family: ${({ checked }) => checked ? 'rubik' : 'Rubik-Medium'};
    font-size: 16px;
    line-height: 20px;
    color: ${({ checked }) => checked ? '#9c9c9c' : '#0D4270'};
    margin: auto;
    margin-right:15px;
 }
 .inactive-unit{
    display: flex;
    font-family: ${({ checked }) => checked ? 'Rubik-Medium' : 'Rubik'};
    font-size: 16px;
    line-height: 20px;
    color: ${({ checked }) => checked ? '#0D4270' : '#9c9c9c'};
    margin: auto;
    margin-left:15px;
 }
 .icon{
    width: 57px;
 }
`;

export { CustomLabel, Slider, Container, CheckBoxContainer };

