import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const metaObj = {
  "/employee-recognition-software": {
    "title": "Best Employee Rewards and Recognition Software | Employee Recognition Platform",
    "metaTags": [
      {
        "name": "description",
        "content": "With Woliba's Employee Recognition software, you can easily recognize and reward employees, boosting productivity, morale and retaining top talent. Get a free demo now!"
      }
    ]
  },
  "/employee-engagement-software": {
    "title": "Best Employee Engagement Survey Software | Employee Engagement Platform",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba's Employee engagement software helps organizations track their employees' feedback, achievements, and milestones to enhance company culture."
      }
    ]
  },
  "/corporate-wellness-software": {
    "title": "Award Winning Corporate Health and Wellness Software | Wellness Corporate Solutions",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba provides you the best corporate health and wellness solutions to improve workplace wellbeing and employee experience. Contact us to get a free consultation."
      }
    ]
  },
  "/company-intranet-software": {
    "title": "Cloud Based Company Intranet Software | Employee Intranet Software",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba's Cloud-based corporate intranet software is available for any size business. Contact us to get a free consultation."
      }
    ]
  },
  "/why-woliba": {
    "title": "Discover Why We're the Top Choice for Your Needs | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Looking to improve your employee's wellbeing? Our corporate wellness program offers tailored solutions to enhance productivity and reduce absenteeism."
      }
    ]
  },
  "/contact": {
    "title": "Woliba - Contact Us",
    "metaTags": [
      {
        "name": "description",
        "content": "We take our commitment to our clients seriously. If you need our help or have questions about the platform, please do not hesitate to contact us."
      }
    ]
  },
  "/about": {
    "title": "Woliba - About Us",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba strives to grow company culture from the ground up with a holistic approach to wellbeing."
      }
    ]
  },
  "/user": {
    "title": "Woliba Login | Amigo Login",
    "metaTags": [
      {
        "name": "description",
        "content": "Login to your dashboard to participate in corporate challenges, engagement surveys, health assessments, and more."
      }
    ]
  },
  "/pricing": {
    "title": "Discover Affordable Wellness Solutions | Woliba Pricing",
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
      // {
      //   "name": "description",
      //   "content": "Woliba wants to support organizations and give back by providing special pricing for qualified nonprofit organizations and educational institutions."
      // }
    ]
  },
  "/thankyou": {
    "title": "Woliba - Thank you",
    "metaTags": [
      {
        "name": "description",
        "content": ""
      }
    ]
  },
  "/corporate-wellness": {
    "title": "Employee Wellness & Motivation App | Book a Demo | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Gamify employee wellness in the workplace with this fun and engaging wellness app. Boost morale and motivation with challenges and activities. Book a demo."
      }
    ]
  },
  "/employee-engagement": {
    "title": "Improve Employee Engagement | Employee Wellness App | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Improve workplace culture and productivity by increasing employee engagement with insightful workplace surveys and more. Request a demo today."
      }
    ]
  },
  "/employee-rewards": {
    "title": "Employee Recognition Software | Employee Wellbeing | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Our employee recognition software provides an easy and efficient way to reward, motivate, and retain employees. Boost employee wellbeing today."
      }
    ]
  },
  "/wellness-admin": {
    "title": "Corporate Wellness | Woliba Corporate Wellness Platform",
    "metaTags": [
      {
        "name": "description",
        "content": "Get energized and motivated with Woliba's Corporate Wellness services. Maximize engagement and save hundreds of hours managing your company programs."
      }
    ]
  },
  "/wellness-challenges": {
    "title": "Workplace Wellbeing and Engagement Software | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba's workplace wellbeing software supports your team with a holistic wellness approach. Use Woliba to foster a positive work-life balance."
      }
    ]
  },
  "/fitness-videos": {
    "title": "Engaging Fitness Videos for Employee Wellbeing | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Boost employee well-being and create a healthier workforce with our workplace fitness videos that can be performed at work or at home. Learn more."
      }
    ]
  },
  "/nutrition-recipes": {
    "title": "Healthy Recipes | Fuel Workplace Wellness | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Support healthy choices and boost overall workplace wellness when you use Woliba to share healthy recipes with your employees. Get a demo of Woliba today."
      }
    ]
  },
  "/wellness-education": {
    "title": "Wellness Education Software | Corporate Wellness | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Give your employees the knowledge they need to take control of their health while improving corporate wellness with our health education software."
      }
    ]
  },
  "/wellness-events": {
    "title": "Online Wellness Events for Employee Well-Being | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Improve employee engagement and help your team improve their health with our online wellness events for employees. Contact us today to learn more."
      }
    ]
  },
  "/activity-challenges": {
    "title": "Employee Wellness Challenges | Increase Engagement | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Woliba's employee wellness challenges help boost employee engagement and contribute to happier, healthier overall corporate wellness. Click to learn more."
      }
    ]
  },
  "/employee-recognition": {
    "title": "Employee Recognition Software That Gets Results | Woliba",
    "metaTags": [
      {
        "name": "description",
        "content": "Learn how Woliba's employee recognition software can help boost morale and create a culture of appreciation. Visit the Woliba website today to learn more!"
      }
    ]
  },
  "/community-page":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/admin":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/pricing-page":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/request-demo":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/mobile-privacy-policy":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/mobile-terms-use":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  },
  "/terms-service":{
    "metaTags": [
      {
        "name": "robots",
        "content": "noindex"
      }
    ]

  }
};

const defaultMetaTags = {
  "title": "Corporate Wellness Software, Platform, and App | Woliba",
  "metaTags": [
    {
      "name": "description",
      "content": "Boost employee satisfaction and motivation with the Woliba corporate wellness software, platform, and app. Improve employee recognition & engagement here."
    }
  ]
};

const MetaTags = (props) => {

  const obj = metaObj[props.history.location.pathname] ? metaObj[props.history.location.pathname] : defaultMetaTags;

  return (
    <Helmet key="helmet">
      <title>{obj.title}</title>
      <link rel="canonical" href={'https://woliba.io' + props.history.location.pathname} />
      {
        obj.metaTags && obj.metaTags.map((tag, index) => (
          <meta key={index} name={tag.name} content={tag.content} />
        ))
      }
    </Helmet>
  );
};

MetaTags.propTypes = {
  history: PropTypes.object
};

export default MetaTags;